import React, { useContext, useEffect, useState } from 'react';
import $ from 'jquery'; 
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import slick from 'slick-carousel';
import logo from '../../Assets/images/1.png';
import logo1 from '../../Assets/images/12.PNG';
import logo2 from '../../Assets/images/13.PNG';
import logo3 from '../../Assets/images/certificate.png';
import logo5 from '../../Assets/images/5.png'; 
import logo55 from '../../Assets/images/5-5.png';
import logo6 from '../../Assets/images/idd.PNG';
import logo7 from '../../Assets/images/بنت.png';
import Certificate from '../../Assets/images/شهادة-اتمام-الدورة.png';
import Clock from '../../Assets/images/ساعة.png';
import Lectuers from '../../Assets/images/عدد-المحاضرات.png';
import style from './Home.module.css';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { useQuery } from 'react-query';
import { BaseUrlContext } from '../../Contexts/BaseUrlContext';
import icon from '../../Assets/images/icon.png'



export default function Home() {
  const [openCards, setOpenCards] = useState({});
  const phoneNumber = "+201117772866";
  const whatsappURL = `https://wa.me/${phoneNumber}`;

  
  const questionsAndAnswers = [
    {
      question: 'كيف يمكنني شراء دورة تدريبية؟',
      answer: 'قم بتحديد الدورة المختارة ثم قم بالضغط على سجل في الدورة وقم بكتابة البيانات المطلوبة. سيقوم أحد ممثلي خدمة عملاء إتقان أكاديمي بالاتصال بك وتفعيل الدورة المختارة.',
    },
    {
      question: 'هل أستطيع تحميل الدورات على جهازي ؟',
      answer: 'لا يمكنك تحميل الدورات تبعًا لقوانين الأكاديمية. ومن يخالف ذلك يعرض نفسه للمعاقبة القانونية.',
    },
    {
      question: 'ماهي وسائل الدفع المتاحة للأشراك في الدورة ؟',
      answer: 'عند إرسال طلب الالتحاق، سيقوم أحد ممثلي خدمة العملاء بالاتصال بك. يمكن الدفع من خلال جميع المحافظ الإلكترونية والحسابات البنكية.',
    },
    {
      question: 'ماهي مدة الأشتراك في الدورات المتاحة على الموقع ؟',
      answer: 'لا يوجد مدة محددة. سنقوم بتفعيل الحساب الخاص بك مدى الحياة.',
    },
    {
      question: 'هل يمكنني الحصول على شهادة , هل هي معتمدة كيف سأحصل عليها ؟',
      answer: 'عند الانتهاء من الدورة يتم إرسال شهادتين بدون رسوم إدارية: الأولى شهادة معتمدة من إتقان أكاديمي، والثانية شهادة معتمدة من شركة أدوبي، الشركة المنتجة للبرامج الخاصة بالدورة. يمكن الحصول على كارنيه مزاولة المهنة داخل جمهورية مصر العربية برسوم إدارية.',
    },
    {
      question: 'السعر المعروض للدورة على المنصة بالجنية المصري و أنا حسابي بالعملة المحلية فكيف يممكني الأشتراك ف الدورة ؟',
      answer: 'يمكنك التغلب على هذه المشكلة بتحويل رسوم الدورة على الحسابات البنكية، ولكن يتم تحديد سعر الدورة تبعًا للدولة الخاصة بك.',
    },
    {
      question: 'هل تقدم أكاديمية إتقان محاضرات انفرادية خاصة أو وجها لوجه ؟',
      answer: 'في الوقت الحالي، جميع الدورات المتاحة هي الدورات المتاحة حاليًا. في حالة وجود قسم الأوفلاين، سنقوم بإرسال رسالة لك بفتح قسم الأوفلاين. يمكنك إرسال رسالة على الرقم التالي للاستفسار عن قسم الأوفلاين: 01140661627.',
    },
    {
      question: 'لدي المزيد من الأسئلة ؟',
      answer: 'يمكن الاشتراك في النشرة البريدية وسنقوم بالتواصل معك. يمكنك التواصل بنا عن طريق الأرقام التالية: 01140661627، 01117772866.',
    },
  ];
  let {baseUrl,setBaseUrl} = useContext(BaseUrlContext);
  const navigate = useNavigate();
  const token = localStorage.getItem('token');

  function getCourses() {
    return axios.get(`${baseUrl}api/courses`, {
      headers: {
          'Authorization': `Bearer ${token}`
      }
    });
    
  }

  function Timer({ endDate }) {
    const [timeLeft, setTimeLeft] = useState(calculateTimeRemaining(endDate));
  
    useEffect(() => {
      const intervalId = setInterval(() => {
        setTimeLeft(calculateTimeRemaining(endDate));
      }, 1000);
  
      return () => clearInterval(intervalId); // تنظيف عند إلغاء المكون
    }, [endDate]);
  
    return <p className={`${style.timer}`}>{timeLeft}</p>;
  }
  
  function calculateTimeRemaining(endDate) {
    const now = new Date();
    const end = new Date(endDate);
    const timeDiff = Math.max(0, end - now);
  
    const days = Math.floor(timeDiff / (1000 * 60 * 60 * 24));
    const hours = Math.floor((timeDiff / (1000 * 60 * 60)) % 24);
    const minutes = Math.floor((timeDiff / (1000 * 60)) % 60);
    const seconds = Math.floor((timeDiff / 1000) % 60);
  
    return `${days}:${hours.toString().padStart(2, '0')}:${minutes
      .toString()
      .padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
  }
  
  function renderTimer(item) {
    if (item.offer?.end_date) {
      return <Timer endDate={item.offer.end_date} />;
    }
    return <p className={`${style.timer}`}>00:00:00:00</p>;
  }
  
  
  
  function getHomeNumbers() {
    return axios.get(`${baseUrl}api/home`, {
      headers: {
          'Authorization': `Bearer ${token}`
      }
    });
  }
  function scrollToCourses() {
    const element = document.getElementById('courses');
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  }
  
  let {isLoading,isFetching,data} = useQuery('getAllCourses', ()=>getCourses());
  let {data: Numbers} = useQuery('getHomeNumbers', ()=>getHomeNumbers());

  
  

  function convertToArabicNumbers(text) {
    const arabicNumerals = '٠١٢٣٤٥٦٧٨٩';
    return text?.replace(/\d/g, (match) => arabicNumerals[parseInt(match)]);
  }

  function signUpCourse(slug) {
    navigate(`/courses/${slug}`);
  
      window.scrollTo({ top: 0, behavior: 'smooth' });
  }

  useEffect(() => {
    const coursesElement = document.getElementById('courses');
    if (coursesElement) {
      const offsetY = coursesElement.offsetTop;
      console.log('Offset Y of #courses:', offsetY);
    }
    const handleScroll = () => {
      const cards = $('#card1, #card2, #card3');
      if (cards.length > 0) {
        const windowHeight = $(window).height();
        const scrollTop = $(window).scrollTop();
        const elementOffsetTop = $('#card1').offset().top;

        if (scrollTop + windowHeight >= elementOffsetTop) {
          $('#card1').css({ opacity: 0, position: 'relative', right: '100px' }).animate({ opacity: 1, right: '0px' }, 1000);
          $('#card2').css({ opacity: 0, position: 'relative', bottom: '100px' }).animate({ opacity: 1, bottom: '0px' }, 2000);
          $('#card3').css({ opacity: 0, position: 'relative', left: '100px' }).animate({ opacity: 1, left: '0px' }, 1000);

          $(window).off('scroll', handleScroll);
        }
      }
    };

    const animateNumbers = () => {
      $('.number').each(function () {
        const $this = $(this);
        const countTo = $this.attr('data-count');
        $({ countNum: $this.text() }).animate(
          { countNum: countTo },
          {
            duration: 2000,
            easing: 'swing',
            step: function () {
              $this.text(Math.floor(this.countNum));
            },
            complete: function () {
              $this.text(this.countNum);
            },
          }
        );
      });
    };

    const elementOffsetTop = $('#container4').offset().top;
    $(window).on('scroll', function () {
      if ($(window).scrollTop() + $(window).height() > elementOffsetTop) {
        animateNumbers();
        $(window).off('scroll');
      }
    });

    $(window).on('scroll', handleScroll);

    // Initialize Slick Slider with autoplay
    const slider = $('.slider').slick({
      infinite: true,
      slidesToShow: 3,
      slidesToScroll: 1,
      arrows: true,
      dots: true,
      autoplay: true,
      autoplaySpeed: 0, // Set to 0 for continuous sliding
      speed: 5000, // Adjust the speed as necessary
      cssEase: 'linear', // Smooth linear animation
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
            infinite: true,
            dots: true,
          },
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
      ],
    });

    // Add click event listener to pause/resume slider
    $('.slider .card').on('click', function () {
      if (slider.slick('slickGetOption', 'autoplay')) {
        slider.slick('slickPause');
      } else {
        slider.slick('slickPlay');
      }
    });

    return () => {
      $(window).off('scroll', handleScroll);
      $(window).off('scroll', animateNumbers);

      if ($('.slider').hasClass('slick-initialized')) {
        $('.slider').slick('unslick');
      }
    };
  }, []);

  const toggleCard = (index) => {
    setOpenCards((prev) => ({
      ...prev,
      [index]: !prev[index],
    }));
  };

  
  return (
    <>
     <a
      href={whatsappURL}
      target="_blank"
      rel="noopener noreferrer"
      className={`${style.whatsappbutton}`}
      
    >
      <i className={`fa-brands fa-whatsapp ${style.whatsappicon}`}></i>
    </a>
      <div className={style.container1}>
        <div className="row align-items-center">
          <div className="col-md-7 pe-4   ">
            <p className={` ${style.para}`}>أكاديمية إتقان</p>
            <p className={style.para2}>المكــــان المناســـب الـــذي تحتـــــــاجه لتتعلــــــــــــم</p>
            <p className={style.para2}> تصميم الجرافيـــك مــــن صفر إلى الأحــــتراف</p>
              
            <p className={`w-100 d-flex  mt-5 ${style.ton}`}>
            <button className={`btn ${style.bttn}`} style={{height:'47px', width:'125px'}} onClick={scrollToCourses}>
              شاهد الدورات
            </button>
          </p>       
           </div>
          <div className={`col-md-5  d-flex flex-column ${style.col}`}>
            <img src={logo} alt="person" className={` ${style.logoo1} position-relative ` } />
            <img src={icon} className={`${style.icona} position-absolute`} alt="" />
          </div>
        </div>
      </div>
      <div className={`${style.container2} pt-5 container-lg`}>
        <div className="content">
          <p className={`${style.para3} fw-bold`}>مــــــاذا نقدم لك فـــي إتقان كاديمي</p>
        </div>
        <div className="row my-5">
          <div className={`${style.boxs} col-md-4 col-sm-6 mb-4`}>
            <div id="card1" className={`card ${style.all} position-relative`}>
              <img src={logo3} alt="" className={`position-absolute ${style.logoo}`} />
              <h2 className={`${style.textt} mb-4`}>شهادة إتمام الدورة</h2>
              <p className={`${style.text1} `}>نمنحك شهادات اتمام حضورك وإتمام للدورة</p>    
              <p className={`${style.text1} `}>تضاعف حظوظـــــك فـــــــــــــي ســــــــوق العــــــــــمل</p>    
            </div>
          </div>
          <div className={`${style.boxs} col-md-4 col-sm-6 mb-4`}>
            <div id="card2" className={`card ${style.all} position-relative`}>
              <img src={logo2} alt="" className={`position-absolute ${style.logoo}`} />
              <h2 className={`${style.textt} mb-4`}>مصادر احترافية</h2>
              <p className={`${style.text1} `}>نشارك معك في الدورات مصادر أحترافية تحتاجها</p>
              <p className={`${style.text1} `}>فـــي تطبيــــــق ما تعلمــــته بشكــــــــــل مجـــــــــــــــاني</p>
            </div>
          </div>
          <div className={`${style.boxs} col-md-4 col-sm-6 mb-4`}>
            <div id="card3" className={`card ${style.all} position-relative`}>
              <img src={logo1} alt="" className={`position-absolute ${style.logoo}`} />
              <h2 className={`${style.textt} mb-4`}>دورات شاملة</h2>
              <p className={`${style.text1} `}>وفر لك دورات أحترافيــــــة تشمــــــل لك كل شــــــــئي</p>
              <p className={`${style.text1} `}>وفيديوهــــــــات دروس سلســــة وسهـــــــــلة الفــــــــــــهم</p>
            </div>
          </div>
        </div>
      </div>
      {/* <div className={`${style.container3} mb-5`}>
        <div className="row align-items-center">
          <div className="col-md-5">
            <img src={logo5} className={`img-fluid ${style.customimage}`} alt="" />
          </div>
          <div className="col-md-7 mt-5">
            <p className={`${style.para4} pe-3 mb-5`}>كـــن مستعـــــد لأخــــذ مهاراتـــك الفنيـــــــة 
               فــــــي رحلــــتك الأبداعـــــــية 
               </p>
            <p className={`${style.para5} pe-3`}>
              تريد أن تتعلم تصميم الجرافيك و تصميم فيديوهات الموشن جرافيك
              وتحرير الفيديو من البداية إلى الأحتــــــــــراف فأنت في المكـــــــــان الصحيح 
            </p>
            <p className="w-100 d-flex justify-content-end pe-3"><button className={`btn ${style.bttn}`}>شاهد الدورات</button></p> 
          </div>
        </div>
      </div> */}
      <div className={`${style.introduction} my-5`}>
        <div className='h-100'>
          <div className='h-100 row align-items-end'>
            <div className='h-100 d-none d-sm-block col-sm-5 position-relative'>
              <img src={logo55} className={`${style.customImg}`} alt="" />
            </div>
            <div className="col-12 col-sm-7">
              <div className={`${style.words} `}>
                <p className={`${style.para4} `}>كـــن مستعـــــد لأخــــذ مهاراتـــك الفنيـــــــة 
               
                  </p>
                <p className={`${style.para4} `}> ... فــــــي رحلــــتك الأبداعـــــــية </p>
                <p className={`${style.para5}`}>
                  تريد أن تتعلم تصميم الجرافيك و تصميم فيديوهات الموشن جرافيك
                </p>
                <p className={`${style.para5}`}>
                .  وتحرير الفيديو من البداية إلى الأحتــــــــــراف فأنت في المكـــــــــان الصحيح 
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container-xl my-5 px-sm-5" id='courses'>
  <p className={`${style.para3} fw-bold pb-4`}>دورات إتـــــــــــــقان أكاديمـــــــــــي</p>
  <div className="row" style={{direction:'rtl'}}>
    {!data && (
      <div className="d-flex justify-content-center align-items-center" style={{ height: '100vh' }}>
        <i className="fa-solid fa-spinner fa-spin" style={{ fontSize: '2rem', color: '#007bff' }}></i>
      </div>
    )}
{data?.data?.data.length > 0 ? (
  data.data.data.map((item) => (
    <div key={item.slug} className="col-md-6 col-lg-4 mb-4">
      <div className={`card ${style.round}`}>
        <div className={`card-header d-flex justify-content-end ${style.bgl1}`} style={{ direction: 'ltr' }}>
          <p className={`text-white w-100 ${style.txt}`}>{renderTimer(item)}</p>
        </div>
        <div className={`card-body ${style.bgl}`}>
          <div className="d-flex justify-content-between" style={{ direction: 'ltr' }}>
            <div className={style.fixedPriceContainer}>
              {!item.is_enrolled && (
                <>
                  {item.discount_price ? (
                    <>
                      <p className={`text-danger ${style.txt} ${style.oldPrice} text-decoration-line-through`}>
                        {convertToArabicNumbers(item.price.toString())} جنيه
                      </p>
                      <p className={`text-white ${style.txt} ${style.discountedPrice}`}>
                        {convertToArabicNumbers((item.price - item.discount_price).toString())} جنيه
                      </p>
                    </>
                  ) : (
                    <p className={`text-white ${style.txt}`}>
                      {convertToArabicNumbers(item.price.toString())} جنيه
                    </p>
                  )}
                </>
              )}
            </div>
            <p className={`text-white ${style.txt} ${style.txt1}`}> دورة {item.title}</p>
          </div>

          <div className="mt-3">
            <p className={`${style.txt} ${style.txt2}`}>بواسطة : {item.instructor?.name}</p>
          </div>
          <div className="mt-4">
            <p className={`${style.txt} ${style.txt2}`}>تقييمات ( {convertToArabicNumbers(item.rating.toFixed(1))} )</p>
          </div>
          <div className="d-flex justify-content-between mt-4">
            <p className={`${style.txt} ${style.txt2} ${style.level} d-flex justify-content-center align-items-center gap-1`}>
              تشمل {item.num_of_levels} <i className="fa-solid fa-sliders"></i>
            </p>
            <p className={`${style.txt} ${style.txt2}`}><img src={Certificate} alt="Certificate" /></p>
          </div>
          <div className="w-100" style={{ backgroundColor: 'white', height: '1px' }}></div>
          <div className="d-flex justify-content-between my-4">
            <div className="d-flex">
              <p className="me-3 text-white">{convertToArabicNumbers(item.lessons.toString())} درس <img className={style.icons} src={Lectuers} alt="Lectuers" /></p>
            </div>
            <div className="d-flex">
              <p className="me-3 text-white">{convertToArabicNumbers(item.total_duration.toString())} ساعة <img className={style.icons} src={Clock} alt="Clock" /></p>
            </div>
            <div className="d-flex">
              <p className="me-3 text-white">{convertToArabicNumbers(item.students_count.toString())}</p>
              <i className="fa-solid fa-user text-white mt-1"></i>
            </div>
          </div>
          <p className="w-100 d-flex justify-content-center">
            <button className={`btn ${style.bttn}`} onClick={() => signUpCourse(item.slug)}>
              {item.is_enrolled ? 'شاهد الدورة' : 'معلومات عن الدورة'}
            </button>
          </p>
        </div>
      </div>
    </div>
  ))
) : (
  <p className="text-center text-white">لا توجد دورات متاحة.</p>
)}

  </div>
</div>

      <div id="container4" className={`${style.container4} my-5`}>
        <div className="d-flex flex-column align-items-center">
          <p className={`${style.txt5} ${style.txt}`}>لمــــاذا أكاديمية إتقـــــــــــان ؟</p>
          <div >
          <p className={`${style.txt6}`}>دورات أحترافية في جميع مجالات تصميم الجرافيك من متدربين محترفين للتتمكن
          من دخول سوق العمل و التعامل باحترافية مع متطلبات العملاء</p>
        </div>
        </div>
        <div className="row mt-3 mt-sm-5">
          <div className="col-md-4">
            <div className={`card ${style.round}`}>
              <div className="d-flex justify-content-center">
                <p className={`${style.txt7} ${style.txt} number`}>+</p>
              <p className={`${style.txt7} ${style.txt} number`} data-count={Numbers?.data?.data?.students_graduates}>{Numbers?.data?.data?.students_graduates}</p>
              </div>
             
              <p className={`${style.txt8} `}>خريجـــــــــين الأكاديمــــية</p>
            </div>
          </div>
          <div className="col-md-4">
            <div className={`card ${style.round}`}>
              <div className="d-flex justify-content-center ">
              <p className={`${style.txt7} ${style.txt} number`}>+</p>
              <p className={`${style.txt7} ${style.txt} number`} data-count={Numbers?.data?.data?.lectures}>{Numbers?.data?.data?.lectures}</p>
              </div>
              <p className={`${style.txt8} `}>فيديــــــو تعليمـــــــي</p>
            </div>
          </div>
          <div className="col-md-4">
           
            <div className={`card ${style.round}`}>
            <div className="d-flex justify-content-center">
            <p className={`${style.txt7} ${style.txt} number`}>+</p>
            <p className={`${style.txt7} ${style.txt} number`} data-count={Numbers?.data?.data?.students_subscribers}>{Numbers?.data?.data?.students_subscribers}</p>
            </div>
              <p className={`${style.txt8} `}>مشتركـين الأكاديمـــــية</p>
            </div>
          </div>
        </div>
      </div>
      <div className={`${style.container5} mt-5 py-5`}>
        <div className="container">
          <p className={`${style.txt9}`}>تقيمات طلابنا</p>
          <p className={`${style.txt10}`}>كلنا فخر و اعتزاز بما يقوله طلاب الأكاديمية</p>
          <div className="slider">
            <div className="col-md-4">
              <div className="card py-3 px-2 mx-2" style={{ backgroundColor: '#50007a', borderRadius: '26px', height: "294px " }}>
                <i className="fa-solid fa-quote-right text-white fs-1 text-center pb-5"></i>
                <p className="fs-4 fw-bold text-white text-center pb-2" style={{ fontFamily: 'IBM Plex Sans Arabic' }}>Zeyad Zayed</p>
                <p className="fs-6 fw-bold text-white text-center pb-2">اكاديمية علي مستوي عالي بجد وحاجة بكواليتي عالي ومحترم واستفادة كبيرة جدًا</p>
              </div>
            </div>
            <div className="col-md-4">
              <div className="card py-3 px-2 mx-2" style={{ backgroundColor: '#50007a', borderRadius: '26px', height: "294px "}}>
                <i className="fa-solid fa-quote-right text-white fs-1 text-center pb-5"></i>
                <p className="fs-4 fw-bold text-white text-center pb-2" style={{ fontFamily: 'IBM Plex Sans Arabic' }}>Salma Mohammed</p>
                <p className="fs-6 fw-bold text-white text-center pb-2">بجد الشرح حلو جدا جدا والطريقة سهلة ومبسطة. الكورس قمته فعلا كبيرة جدا وهنصح بيه أي حد عايز يخش في المجال</p>
              </div>
            </div>
            <div className="col-md-4">
              <div className="card py-3 px-2 mx-2" style={{ backgroundColor: '#50007a', borderRadius: '26px', height: "294px " }}>
                <i className="fa-solid fa-quote-right text-white fs-1 text-center pb-5"></i>
                <p className="fs-4 fw-bold text-white text-center pb-2" style={{ fontFamily: 'IBM Plex Sans Arabic' }}>Amr Essa</p>
                <p className="fs-6 fw-bold text-white text-center pb-2">ربنا يزيدكم علم ونفع للناس بصراحة تستاهلو كل خير شرح مبسط وتفاصيل دقيقة تقود للافضل</p>
              </div>
            </div>
            <div className="col-md-4"> 
              <div className="card py-3 px-2 mx-2" style={{ backgroundColor: '#50007a', borderRadius: '26px', height: "294px " }}>
                <i className="fa-solid fa-quote-right text-white fs-1 text-center pb-5"></i>
                <p className="fs-4 fw-bold text-white text-center pb-2" style={{ fontFamily: 'IBM Plex Sans Arabic' }}>Abdallah gamal</p>
                <p className="fs-6 fw-bold text-white text-center pb-2">اكاديمية عالية جدا جدا جدا ربنا يخليكم ل طلاب مصر ويوفقكم يا رب</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={`${style.container5} py-5`}>
        <div className="container">
          <div className="row">
            <div className="col-md-7">
              <img src={logo7} className='w-100' alt="" />
            </div>
            <div className="col-md-5 d-flex flex-column justify-content-center">
              {questionsAndAnswers.map((item, index) => (
                <div key={index}>
                  <div
                    className="card my-2"
                    style={{
                      width: '100%',
                      backgroundColor: '#50007a',
                      color: 'white',
                      padding: '10px 20px',
                      borderRadius: openCards[index] ? '10px 10px 0 0' : '10px',
                      boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
                      flexDirection: 'row',
                      display: 'flex',
                      justifyContent: 'end',
                      cursor: 'pointer',
                    }}
                    onClick={() => toggleCard(index)}
                  >
                    <p style={{ margin: 0, textAlign: 'right' }}>{item.question}</p>
                    <i
                      className="fa-solid fa-chevron-down fs-4"
                      style={{ marginLeft: '12px', marginTop: '2px', fontSize: '12px' }}
                    ></i>
                  </div>
                  {openCards[index] && (
                    <div
                      style={{
                        width: '100%',
                        backgroundColor: '#50007a',
                        color: 'white',
                        padding: '10px 20px',
                        borderRadius: '0 0 10px 10px',
                        boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
                        marginTop: '-10px',
                      }}
                    >
                      <p style={{ margin: 0, textAlign: 'right' }}>{item.answer}</p>
                    </div>
                  )}
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
