import React, { useRef, useState } from 'react';
import style from './VideoGallery.module.css';
import video1 from '../../Assets/videos/1.mp4';
import video5 from '../../Assets/videos/5.mp4';
import video6 from '../../Assets/videos/6.mp4';
import video7 from '../../Assets/videos/7.mp4';
import video8 from '../../Assets/videos/8.mp4';
import video9 from '../../Assets/videos/9.mp4';
import video10 from '../../Assets/videos/10.mp4';
import video11 from '../../Assets/videos/11.mp4';
import video12 from '../../Assets/videos/12.mp4';
import video34 from '../../Assets/videos/34.mp4';
import videoCar2 from '../../Assets/videos/car2.mp4';
import videoComp1_1 from '../../Assets/videos/Comp 1_1.mp4';
import videoF from '../../Assets/videos/f.mp4';
import videoFirst from '../../Assets/videos/first.mp4';
import videoS from '../../Assets/videos/s.mp4';
import videoT from '../../Assets/videos/t.mp4';
import { FaExpand } from 'react-icons/fa';

export default function VideoGallery() {
  const videos = [
    video1, video5, video6, video7, video8,
    video9, video10, video11, video12, video34,
    videoCar2, videoComp1_1, videoF, videoFirst,
    videoS, videoT,
  ];

  const videoRefs = useRef([]);
  const [fullscreenIndex, setFullscreenIndex] = useState(null); // لتتبع أي فيديو في وضع التكبير

  const handleFullScreen = (index) => {
    const videoElement = videoRefs.current[index];
    if (videoElement) {
      if (videoElement.requestFullscreen) {
        videoElement.requestFullscreen();
      } else if (videoElement.webkitRequestFullscreen) { 
        videoElement.webkitRequestFullscreen();
      } else if (videoElement.msRequestFullscreen) { 
        videoElement.msRequestFullscreen();
      }
      setFullscreenIndex(index); // تعيين الفيديو الحالي إلى وضع التكبير
    }
  };

  return (
    <div className={style.videoGallery}>
      <h2 className={`mb-4 ${style.txt}`}>الفيديوهات</h2>
      <div className={style.galleryGrid}>
        {videos.map((src, index) => (
          <div 
            key={index} 
            className={`${style.videoContainer} ${fullscreenIndex === index ? style.fullscreen : ''}`}
          >
            <div className={`${style.pos}`}>
            <video
              controls
              className={style.galleryItem}
              ref={(ref) => (videoRefs.current[index] = ref)}
            >
              <source src={src} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
            <div
              className={style.fullscreenOverlay}
              onClick={() => handleFullScreen(index)} // عند الضغط على الطبقة التكبير
            >
              <FaExpand className={style.fullscreenIcon} />
            </div>
            </div>
            
          </div>
        ))}
      </div>
    </div>
  );
}
