import React, { useState } from 'react';
import style from './ImageGallery.module.css';
import menna from '../../Assets/images/menna.jpg';
import gallery from '../../Assets/images/gallery.jpg';
import gallery1 from '../../Assets/images/gallery1.jpg';
import gallery2 from '../../Assets/images/gallery2.jpg';
import gallery3 from '../../Assets/images/gallery3.jpg';
import gallery4 from '../../Assets/images/gallery4.jpg';
import gallery5 from '../../Assets/images/gallery5.jpg';
import gallery6 from '../../Assets/images/gallery6.jpg';
import gallery7 from '../../Assets/images/gallery7.jpg';
import gallery8 from '../../Assets/images/gallery8.jpg';
import gallery9 from '../../Assets/images/gallery9.jpg';
import gallery10 from '../../Assets/images/gallery10.jpg';
import gallery11 from '../../Assets/images/gallery11.jpg';
import gallery12 from '../../Assets/images/gallery12.jpg';
import gallery13 from '../../Assets/images/gallery13.jpg';
import gallery14 from '../../Assets/images/gallery14.jpg';
import gallery15 from '../../Assets/images/gallery15.jpg';
import gallery16 from '../../Assets/images/gallery16.jpg';
import gallery17 from '../../Assets/images/gallery17.jpg';
import gallery18 from '../../Assets/images/gallery18.jpg';

export default function ImageGallery() {
  const images = [
    menna,
    gallery,
    gallery1,
    gallery2,
    gallery3,
    gallery4,
    gallery5,
    gallery6,
    gallery7,
    gallery8,
    gallery9,
    gallery10,
    gallery11,
    gallery12,
    gallery13,
    gallery14,
    gallery15,
    gallery16,
    gallery17,
    gallery18,
  ];

  const [selectedImageIndex, setSelectedImageIndex] = useState(null);

  const handleImageClick = (index) => {
    setSelectedImageIndex(index);
  };

  const handleClose = () => {
    setSelectedImageIndex(null);
  };

  const handleNext = () => {
    setSelectedImageIndex((prevIndex) =>
      prevIndex === images.length - 1 ? 0 : prevIndex + 1
    );
  };

  const handlePrevious = () => {
    setSelectedImageIndex((prevIndex) =>
      prevIndex === 0 ? images.length - 1 : prevIndex - 1
    );
  };

  return (
    <div className={style.imageGallery}>
      <h2 className={`mb-4 ${style.txt}`}>الصور</h2>
      <div className={style.galleryGrid}>
        {images.map((src, index) => (
          <img
            key={index}
            src={src}
            alt={`Student work ${index + 1}`}
            className={style.galleryItem}
            onClick={() => handleImageClick(index)}
          />
        ))}
      </div>

      {selectedImageIndex !== null && (
        <div className={style.overlay}>
          <div className={style.modal}>
            <button className={style.closeButton} onClick={handleClose}>X</button>
            <button className={style.arrow} onClick={handlePrevious}>←</button>
            <img src={images[selectedImageIndex]} alt="Selected" className={style.modalImage} />
            <button className={style.arrow} onClick={handleNext}>→</button>
          </div>
        </div>
      )}
    </div>
  );
}
