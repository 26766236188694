import React, { useContext, useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import axios from 'axios';
import style from './Login.module.css'; // استخدم نفس ملف CSS
import { Link, useNavigate } from 'react-router-dom';
import { BaseUrlContext } from '../../Contexts/BaseUrlContext';
import email from '../../Assets/images/email.PNG'
import location from '../../Assets/images/location.PNG'

export default function Login() {
  let nav = useNavigate();
  const [successMessage, setSuccessMessage] = useState('');
  const [contactMessage, setContactMessage] = useState('');
  let {baseUrl,setBaseUrl} = useContext(BaseUrlContext);

  const refreshToken = async () => {
    try {
      const response = await axios.post(`${baseUrl}auth/refresh`, null, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`
        }
      });
      const newToken = response.data.data.access_token;
      localStorage.setItem('token', newToken);
    } catch (error) {
      console.error('Failed to refresh token:', error);
    }
  };
  

  const formik = useFormik({
    initialValues: {
      email: '',
      password: ''
    },
    validationSchema: Yup.object({
      email: Yup.string().email('يجب أن يكون بريد إلكتروني صالح').required('مطلوب'),
      password: Yup.string().required('مطلوب').min(8, 'يجب أن تكون كلمة المرور 8 أحرف على الأقل')
    }),
    onSubmit: values => {
      axios.post(`${baseUrl}api/auth/login`, values)
        .then(response => {
          const token = response.data.data.access_token;
          localStorage.setItem('token', token);
          setSuccessMessage('تم تسجيل الدخول بنجاح');
          formik.resetForm();
          nav('/My-Profile');
        })
        .catch(error => {
          setSuccessMessage('البريد الإلكتروني أو كلمة المرور غير صحيحة. يُرجى المحاولة مرة أخرى.');
        });
    }
  });

  const handleContactSubmit = (e) => {
    e.preventDefault();
    const formData = new FormData(e.target);
  
    const data = {
      name: formData.get('name'),
      email: formData.get('email'),
      phone: formData.get('phone'),
      message: formData.get('message')
    };
  
    axios.post(`${baseUrl}api/send-inquiry`, data)
      .then(response => {
        setContactMessage('تم إرسال استفسارك بنجاح');
        e.target.reset();
  
        // Meta Pixel code for tracking Lead event
        if (window.fbq) {
          window.fbq('track', 'Lead');
        }
      })
      .catch(error => {
        setContactMessage('فشل في إرسال الاستفسار، حاول مرة أخرى');
      });
  }
  

  return (
    <>
      <p className={`${style.para}`}> تسجيل الدخول </p>
      <p className={`${style.para2}`}>أدخل بياناتك لتسجيل الدخول</p>
      <div className="container my-5">
      {successMessage ? <div className={`alert alert-danger my-5 ${style.rtl}`}>{successMessage}</div> : ''}
        <form onSubmit={formik.handleSubmit}>
          <div className="row mb-5">
            <div className="col-md-12">
              <p className={`${style.para3}`}>الإيميل الخاص بك</p>
              <input 
                type="email" 
                className={`${style.formm} form-control ${formik.touched.email && formik.errors.email ? style.errorBorder : ''}`} 
                {...formik.getFieldProps('email')} 
              />
              {formik.touched.email && formik.errors.email ? (
                <div className={style.errorMessage}>{formik.errors.email}</div>
              ) : null}
            </div>
          </div>
          <div className="row mb-5">
            <div className="col-md-12">
              <p className={`${style.para3}`}>كلمة السر</p>
              <input 
                type="password" 
                className={`${style.formm} form-control ${formik.touched.password && formik.errors.password ? style.errorBorder : ''}`} 
                {...formik.getFieldProps('password')} 
              />
              {formik.touched.password && formik.errors.password ? (
                <div className={style.errorMessage}>{formik.errors.password}</div>
              ) : null}
            </div>
          </div>
          <div className="row">
            <div className="col-md-6">
              <button type="submit" className={`btn ${style.btt}`}>تسجيل الدخول</button>
            </div>
          </div>
          <div>
            <p className='text-center'><Link to={'/Register'} className={`${style.para51}`}>انشاء حساب </Link></p>
          </div>
        </form>
      </div>

      <div className={`${style.container2} `} style={{marginTop:"20%"}} >
        <div className="container">
          <div className="row">
            <div className={`col-md-6 ${style.kn}`} style={{paddingRight:'11rem'}}>
              <p className='text-end'><img src={location} alt="" /></p>
              <p className={`${style.location}`}>العـــــــــنوان</p>
              <p className={`${style.location1}`}>محافظة - الجيزة - ٦ أكتوبر - المحور المركزي - الحي العاشر</p>
              <p className={`${style.location1}`}>بجوار سيلفر مول - ETE  اكاديمية</p>
            </div>
            <div className={`col-md-5 ${style.kn2}`} style={{paddingRight:'4rem'}}>
            <p className='text-end'><img src={email} alt="" style={{height:"95px"}}/></p>
              <p className={`${style.location}`}>البريـــــد الإلكترونـــــي</p>
              <p className={`${style.location1}`}>etqanacademy29@gmail.com</p>
            </div>
          </div>
        </div>
      </div>
      <div className={`${style.container3}`}>
        <div className="container">
          <div className={`${style.formTitle} my-5`}>أرسل استفسارك</div>
          {contactMessage ? <div className={`alert alert-success ${style.rtl}`}>{contactMessage}</div> : ''}
          <form onSubmit={handleContactSubmit}>
            <div className={`${style.formGroup}`}>
              <input type="text" className={`${style.formControl} my-3`} placeholder="الاسم بالكامل" name="name" />
            </div>
            <div className={`${style.formGroup} my-3`}>
              <input type="email" className={`${style.formControl} my-3`} placeholder="البريد الإلكتروني" name="email" />
            </div>
            <div className={`${style.formGroup} my-3`}>
              <input type="text" className={`${style.formControl} my-3`} placeholder="رقم الهاتف" name="phone" />
            </div>
            <div className={`${style.formGroup} my-3`}>
              <textarea className={`${style.formControl} my-3`} rows="4" placeholder="الرسالة *" name="message"></textarea>
            </div>
            <button type="submit" className={`${style.btnSubmit} mt-3 mb-5`}>أرسل</button>
          </form>
        </div>
      </div>
    </>
  );
}
