import React, { useContext, useEffect, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import style from './Navbar.module.css';
import logo from '../../Assets/images/academy-removebg-preview.png';
import { BaseUrlContext } from '../../Contexts/BaseUrlContext';
import axios from 'axios';
import { toast, Toaster } from 'react-hot-toast';


export default function Navbar() {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [navigateAndScroll, setNavigateAndScroll] = useState(false);
  const [showSearch, setShowSearch] = useState(false); 
  const [query, setQuery] = useState('');
  const { baseUrl } = useContext(BaseUrlContext);
  const [results, setResults] = useState([]);
  const [showResults, setShowResults] = useState(false);
  const [menuOpen, setMenuOpen] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  const [isScrolled, setIsScrolled] = useState(false);
  const [errorMessage, setErrorMessage] = useState(''); 
  
  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token) {
      setIsAuthenticated(true);
    }
  }, []);

  useEffect(() => {
  const handleScroll = () => {
    setIsScrolled(window.scrollY > 0); 
  };

  window.addEventListener('scroll', handleScroll);

  return () => {
    window.removeEventListener('scroll', handleScroll);
  };
}, []);


  useEffect(() => {
    const token = localStorage.getItem('token');
    setIsAuthenticated(!!token);
  }, [location]); 

  useEffect(() => {
    if (navigateAndScroll && location.pathname === '/') {
      const coursesElement = document.getElementById('courses');
      if (coursesElement) {
        coursesElement.scrollIntoView({ behavior: 'smooth' });
      }
      setNavigateAndScroll(false);
    }
  }, [location, navigateAndScroll]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      const navLinks = document.querySelector(`.navLinksUl`);
      const search = document.querySelector(`.inputsearch`);
      if (menuOpen && navLinks && !navLinks.contains(event.target)) {
        closeMenu();
      }
      if (showSearch && search && !search.contains(event.target)) {
        closeSearch();
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [menuOpen]);

  const handleScroll = () => {
    if (location.pathname === '/') {
      setNavigateAndScroll(true);
    } else {
      setNavigateAndScroll(true);
      navigate('/');
    }
  };

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  const viewUserProfile = (slug) => {
    scrollToTop();
    navigate(`/User/${slug}`); 
  };

  const getLinkClassName = (path) => {
    return location.pathname === path ? style.active : '';
  };

  const handleSearch = async () => {
    const token = localStorage.getItem('token'); 
    try {
      const response = await fetch(`${baseUrl}api/students/search?q=${query}`, {
        headers: {
          Authorization: `Bearer ${token}`, 
        },
      });
  
      if (response.ok) {
        const { data } = await response.json();
        setResults(data); 
        setShowResults(true); 
        setErrorMessage(''); 
        scrollToTop(); 
  
        if (window.fbq) {
          window.fbq('track', 'Search');
        }
      } else {
        const errorResponse = await response.json();
        setErrorMessage(errorResponse.message || 'حدث خطأ أثناء البحث');
        toast.error(errorResponse.message);
      }
    } catch (error) {
      setErrorMessage('حدث خطأ في الاتصال بالخادم.');
      console.error('Error:', error);
    }
  };
  

  const closeResults = () => {
    setShowResults(false); 
  };

  const closeMenu = () => {
    setMenuOpen(false);
  };

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  const closeSearch = () => {
    setShowSearch(false);
  };

  const toggleSearch = () => {
    setShowSearch(!showSearch);
  };
  async function getUserProfile(Slug) {
    const token = localStorage.getItem('token');
    try{

    
    let response = await axios.get(`${baseUrl}api/students/${Slug}`,{
      headers: {
        Authorization: `Bearer ${token}`, 
      },
    })
    if(response.status==200){
      const {data} = response.data;
      console.log(data);
      
    }
    else{
      console.error('Error fetching data:', response.statusText);
    }
  }
  catch (error) {
    console.error('Error:', error);
  }
  
  };

  return (
    <nav className={`${style.navbar} ${isScrolled ? style.fixedNavbar : ''}`}>

      <div className={style.logo} onClick={scrollToTop}>
        <img src={logo} className={`${style.academy}`} alt="Etqan Academy" />
      </div>

      <ul className={`navLinksUl ${style.navLinks} ${menuOpen ? style.mobile : ''}`}>
        <li><Link to={'/'} className={getLinkClassName('/')} onClick={() => { scrollToTop(); closeMenu(); }}> الرئيسية</Link></li>
        <li><Link to={'/Who'} className={getLinkClassName('/Who')} onClick={() => { scrollToTop(); closeMenu(); }}>من نحن؟</Link></li>
        <li onClick={() => { handleScroll(); scrollToTop(); closeMenu(); }} style={{ cursor: 'pointer' }}>الدورات</li>
        <li> <Link to={'/studentGallery'} className={getLinkClassName('/studentGallery')} onClick={() => { scrollToTop(); closeMenu(); }}>
         أعمال الطلبة </Link>
        </li>
        <li><Link to={'/contact'} className={getLinkClassName('/contact')} onClick={() => { scrollToTop(); closeMenu(); }}>تواصل معنا</Link></li>
        {!isAuthenticated && (
          <li><Link to={'/Register'} className={getLinkClassName('/Register')} onClick={() => { scrollToTop(); closeMenu(); }}><i className="fa-solid fa-user-plus text-white ms-2"></i>تسجيل حساب</Link></li>
        )}
      </ul>

      <div className={style.icons}>
        <label id="check">
          <i className={`fas fa-bars ${menuOpen ? 'd-none' : 'd-block'}`} onClick={()=>{toggleMenu(); closeSearch();}} id={style.btn}></i>
          <i className={`fas fa-times ${menuOpen ? 'd-block' : 'd-none'}`} onClick={closeMenu} id={style.cancel}></i>
        </label>
        <i
          onClick={toggleSearch}
          className={`fa-solid fa-magnifying-glass text-white ${style.icon} ms-0 ms-sm-3 mt-2`}
          style={{ cursor: 'pointer' }}
        ></i>
        {showSearch && (
          <input
            type="text"
            className={`inputsearch ${style.searchInput}`}
            placeholder="ابحث عن مستخدم"
            value={query}
            onChange={(e) => setQuery(e.target.value)}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                handleSearch();
              }
            }}
          />
        )}
        <Link to={'/My-Profile'} className={getLinkClassName('/My-Profile')} onClick={scrollToTop}>
          <i className={`fa-solid fa-user text-white ${style.icon} ${style.border}`}></i>
        </Link>
      </div>

      {showResults && (
        <div className={style.bgCover}>
          <div className={style.resultsModal}>
            <button className={style.closeButton} onClick={closeResults}>×</button>
            <div className={`w-100 row`}>
              {results.map((user) => (
                <div key={user.slug} className='col-12 col-md-6 col-lg-4 mb-4'>
                  <div className={style.card} onClick={() => { viewUserProfile(user.slug); getUserProfile(user.slug); }}>
                    <img src={user.image} alt={`${user.first_name} ${user.last_name}`} className={style.userImage} />
                    <div className={style.userInfo}>
                      <h3>{user.first_name} {user.last_name}</h3>
                      <p>{user.job_title}</p>
                      <p>{user.email}</p>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      )}
    </nav>
  );
}
