import React, { useState } from 'react';
import style from './SocialMedia.module.css';
import menna from '../../Assets/images/12-01.jpg';
import gallery from '../../Assets/images/12-02.jpg';
import gallery1 from '../../Assets/images/12-03.jpg';
import gallery2 from '../../Assets/images/12-04.jpg';
import gallery3 from '../../Assets/images/12-05.jpg';
import gallery4 from '../../Assets/images/12-06.jpg';
import gallery5 from '../../Assets/images/12-07.jpg';
import gallery6 from '../../Assets/images/12-08.jpg';
import gallery7 from '../../Assets/images/12-09.jpg';
import gallery8 from '../../Assets/images/12-10.jpg';
import gallery9 from '../../Assets/images/12-11.jpg';
import gallery10 from '../../Assets/images/12-12.jpg';
import gallery11 from '../../Assets/images/12-13.jpg';
import gallery12 from '../../Assets/images/12-14.jpg';
import gallery13 from '../../Assets/images/12-15.jpg';
import gallery14 from '../../Assets/images/12-16.jpg';
import gallery15 from '../../Assets/images/12-17.jpg';
import gallery16 from '../../Assets/images/12-18.jpg';
import gallery17 from '../../Assets/images/12-19.jpg';
import gallery19 from '../../Assets/images/12-20.jpg';
import gallery20 from '../../Assets/images/12-21.jpg';
import gallery21 from '../../Assets/images/12-22.jpg';
import gallery22 from '../../Assets/images/12-22.jpg';
import gallery23 from '../../Assets/images/12-23.jpg';
import gallery24 from '../../Assets/images/12-24.jpg';
import gallery25 from '../../Assets/images/12-25.jpg';
import gallery26 from '../../Assets/images/12-26.jpg';
import gallery27 from '../../Assets/images/12-27.jpg';
import gallery28 from '../../Assets/images/12-27.jpg';
import gallery29 from '../../Assets/images/photo_2024-02-07_01-36-22.jpg';
import gallery30 from '../../Assets/images/photo_2024-02-07_01-36-23.jpg';
import gallery31 from '../../Assets/images/photo_2024-02-07_01-36-24.jpg';
import gallery32 from '../../Assets/images/photo_2024-02-07_01-36-25.jpg';
import gallery33 from '../../Assets/images/photo_2024-02-07_01-36-26.jpg';
import gallery34 from '../../Assets/images/photo_2024-02-07_01-39-19.jpg';

export default function SocialMedia() {
  const images = [
    menna,
    gallery,
    gallery1,
    gallery2,
    gallery3,
    gallery4,
    gallery5,
    gallery6,
    gallery7,
    gallery8,
    gallery9,
    gallery10,
    gallery11,
    gallery12,
    gallery13,
    gallery14,
    gallery15,
    gallery16,
    gallery17,
    gallery19,
    gallery20,
    gallery21,
    gallery22,
    gallery23,
    gallery24,
    gallery25,
    gallery26,
    gallery27,
    gallery28,
    gallery29,
    gallery30,
    gallery31,
    gallery32,
    gallery33,
    gallery34,
  ];

  const [selectedImageIndex, setSelectedImageIndex] = useState(null);

  const handleImageClick = (index) => {
    setSelectedImageIndex(index);
  };

  const handleClose = () => {
    setSelectedImageIndex(null);
  };

  const handleNext = () => {
    setSelectedImageIndex((prevIndex) =>
      prevIndex === images.length - 1 ? 0 : prevIndex + 1
    );
  };

  const handlePrevious = () => {
    setSelectedImageIndex((prevIndex) =>
      prevIndex === 0 ? images.length - 1 : prevIndex - 1
    );
  };

  return (
    <div className={style.imageGallery}>
      <h2 className={`mb-4 ${style.txt}`}>الصور</h2>
      <div className={style.galleryGrid}>
        {images.map((src, index) => (
          <img
            key={index}
            src={src}
            alt={`Student work ${index + 1}`}
            className={style.galleryItem}
            onClick={() => handleImageClick(index)}
          />
        ))}
      </div>

      {selectedImageIndex !== null && (
        <div className={style.overlay}>
          <div className={style.modal}>
            <button className={style.closeButton} onClick={handleClose}>X</button>
            <button className={style.arrow} onClick={handlePrevious}>←</button>
            <img src={images[selectedImageIndex]} alt="Selected" className={style.modalImage} />
            <button className={style.arrow} onClick={handleNext}>→</button>
          </div>
        </div>
      )}
    </div>
  );
}
