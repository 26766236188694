import React, { useEffect } from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import '@fortawesome/fontawesome-free/css/all.min.css';
import '@fontsource/ibm-plex-sans-arabic/700.css';
import '@fortawesome/fontawesome-free/css/all.css';

import { QueryClient, QueryClientProvider } from 'react-query';

// Create a new QueryClient instance
let queryClient = new QueryClient();

const root = ReactDOM.createRoot(document.getElementById('root'));

// Declare `fbq` globally to avoid ESLint error
/* global fbq */

function AppWrapper() {
  useEffect(() => {
    (function(f, b, e, v, n, t, s) {
      if (f.fbq) return;
      n = f.fbq = function() {
        n.callMethod ? n.callMethod.apply(n, arguments) : n.queue.push(arguments);
      };
      if (!f._fbq) f._fbq = n;
      n.push = n;
      n.loaded = !0;
      n.version = '2.0';
      n.queue = [];
      t = b.createElement(e);
      t.async = !0;
      t.src = v;
      s = b.getElementsByTagName(e)[0];
      s.parentNode.insertBefore(t, s);
    })(window, document, 'script', 'https://connect.facebook.net/en_US/fbevents.js');
  
    if (window.fbq) {
      fbq('init', '442428452247271'); // Facebook Pixel ID
      fbq('track', 'PageView'); // Track page view event
    }
  }, []);
  

  return (
    <QueryClientProvider client={queryClient}>
      <App />
    </QueryClientProvider>
  );
}

root.render(
  <React.StrictMode>
    <AppWrapper />
  </React.StrictMode>
);

reportWebVitals();
